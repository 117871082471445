import Vue from 'vue'
// import * as Vue from 'vue'
// import VueHead from 'vue-head'
// === old SWIPER 4.5.1
// import Swiper from "swiper/dist/js/swiper.min.js"
// import "swiper/dist/css/swiper.min.css"
// === new Swiper 7.4.1
import Swiper from 'swiper/bundle';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/parallax';
// Cookie Consent
import "../src/vendor/cookieconsent-master/src/cookieconsent.js";

import VueScrollTo from 'vue-scrollto'

// import App from './App.vue'
const App = () => import(/* webpackChunkName: "Appvue" */'./App.vue');
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Vue.use(VueHead)

Vue.use(VueScrollTo, {
    duration: 1000,
    offset: -80
})

Vue.prototype.$scrollToTop = function() {
    store.commit("setScrollPos", 0);
    window.scrollTo(0, 0);
}

Vue.prototype.$swiper = Swiper

Vue.mixin({
    methods: {
        validateEmail(email) {
            const regex = /^[a-z\d]+[\w\d.-]*@(?:[a-z\d]+[a-z\d-]+\.){1,5}[a-z]{2,6}$/i;
            return regex.test(email);
        }
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')