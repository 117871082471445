const modal = {
    state: {
        showModal: false,
        modalImage: ""
    },
    getters: {
        showModal(state) {
            return state.showModal
        },
        modalImage(state) {
            return state.modalImage
        },
    },
    mutations: {
        setShowModal(state, value) {
            state.showModal = value
        },
        setModalImage(state, value) {
            state.modalImage = value
        },
    },
    actions: {
        setShowModal(context, value) {
            context.commit('setShowModal', value)
        },
        setModalImage(context, value) {
            context.commit('setModalImage', value)
        },
    }
}

export default modal;