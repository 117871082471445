import Vue from 'vue'
import Router from 'vue-router'
// import Home from './views/Home.vue'
const Home = () => import(/* webpackChunkName: "viewsHome" */ './views/Home.vue')
// import Posts from './views/Posts.vue'
const Posts = () => import(/* webpackChunkName: "viewsPosts" */ './views/Posts.vue')
// import Post from './views/Post.vue'
const Post = () => import(/* webpackChunkName: "viewsPost" */ './views/Post.vue')
// import Contact from './views/Contact.vue'
const Contact = () => import(/* webpackChunkName: "viewsContact" */ './views/Contact.vue')
// import About from './views/About.vue'
const About = () => import(/* webpackChunkName: "viewsAbout" */ './views/About.vue')
// import NotFound from './views/NotFound.vue'
const NotFound = () => import(/* webpackChunkName: "viewsNotFound" */ './views/NotFound.vue')
// import Terms from './views/Terms.vue'
const Terms = () => import(/* webpackChunkName: "viewsTerms" */ './views/Terms.vue')
// import store from './store'
const Webinar = () => import(/* webpackChunkName: "viewsTerms" */ './views/Webinar.vue')

Vue.use(Router)

export default new Router({
    mode: 'history',
    // base: process.env.BASE_URL, // + '/yis_dev/', // remove for deployement
    // history: createWebHashHistory(),    
    routes: [{
            path: '/',
            name: 'home-page',
            component: Home,
            meta: {
                logo_on_top: false
            }
        },
        {
            path: '/news',
            name: 'news',
            component: Posts,
            meta: {
                name: 'post',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/news/tag/:tag',
            name: 'news-tag',
            component: Posts,
            meta: {
                name: 'post',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/profiles',
            name: 'profiles',
            component: Posts,
            meta: {
                name: 'profiles',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/profiles/tag/:tag',
            name: 'profiles-tag',
            component: Posts,
            meta: {
                name: 'profiles',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/events',
            name: 'events',
            component: Posts,
            meta: {
                name: 'events',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/events/tag/:tag',
            name: 'events-tag',
            component: Posts,
            meta: {
                name: 'events',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/luxuryindustrytalks',
            name: 'luxuryindustrytalks',
            component: Posts,
            meta: {
                name: 'luxuryindustrytalks',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/luxuryindustrytalks/tag/:tag',
            name: 'luxuryindustrytalks-tag',
            component: Posts,
            meta: {
                name: 'luxuryindustrytalks',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/jobs',
            name: 'jobs',
            component: Posts,
            meta: {
                name: 'jobs',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/jobs/tag/:tag',
            name: 'jobs-tag',
            component: Posts,
            meta: {
                name: 'jobs',
                logo_on_top: false,
                posts: true
            }
        },
        // Uncomment to release partners
        // {
        //     path: '/partners',
        //     name: 'partners',
        //     component: Posts,
        //     meta: {
        //         name: 'partners',
        //         logo_on_top: false,
        //         posts: true
        //     }
        // },
        // {
        //     path: '/partners/tag/:tag',
        //     name: 'partners-tag',
        //     component: Posts,
        //     meta: {
        //         name: 'partners',
        //         logo_on_top: false,
        //         posts: true
        //     }
        // },
        {
            path: '/contact',
            name: 'contact',
            component: Contact,
            meta: {
                logo_on_top: false
            }
        },
        {
            path: '/about',
            name: 'about',
            component: About,
            meta: {
                name: 'about',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/about/tag/:tag',
            name: 'about-tag',
            component: About,
            meta: {
                name: 'about',
                logo_on_top: false,
                posts: true
            }
        },
        {
            path: '/:name/:slug',
            name: 'post',
            component: Post,
            meta: {
                logo_on_top: true
            }
        },
        {
            path: '/404',
            name: 'not-found',
            component: NotFound,
            meta: {
                logo_on_top: true
            }
        },
        {
            path: '/terms-conditions',
            name: 'terms-conditions',
            component: Terms,
            meta: {
                logo_on_top: true
            }
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: Terms,
            meta: {
                logo_on_top: true
            }
        },
        {
            path: '/cookies-policy',
            name: 'cookies-policy',
            component: Terms,
            meta: {
                logo_on_top: true
            }
        },
        {
            path: '/disclaimer',
            name: 'disclaimer',
            component: Terms,
            meta: {
                logo_on_top: true
            }
        },
        {
            path: '/webinar-honest-talks-about-honest-design',
            name: 'webinar',
            component: Webinar,
            meta: {
                logo_on_top: true                
            }
        },
        {
            path: '*',
            redirect: '/404'
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.meta.posts && from.name === 'post') {
            return
        } else {
            if (savedPosition) {
                this.app.$store.commit("setScrollPos", savedPosition.y);
                // if (!to.meta.logo_on_top && savedPosition.y > 80) {
                //     this.app.$store.commit("setLogoOnTop", true);
                // }
            }
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ x: 0, y: 0 })
                        // resolve(savedPosition)
                }, 500)
            })
        }
    }
})