const posts = {
    state: {
        posts: null,
        pageCount: 0,
        currentPage: 1,
        perPage: 0,
        cutEl: 14,
        scrollTopPos: 0,
        postsArrays: [],
        postsTag: null,
        homePosts: null
    },
    getters: {
        posts(state) {
            return state.posts
        },
        pageCount(state) {
            return state.pageCount
        },
        currentPage(state) {
            return state.currentPage
        },
        perPage(state) {
            return state.perPage
        },
        cutEl(state) {
            return state.cutEl
        },
        scrollTopPos(state) {
            return state.scrollTopPos
        },
        postsArrays(state) {
            return state.postsArrays
        },
        postsTag(state) {
            return state.postsTag
        },
        homePosts(state) {
            return state.homePosts
        }
    },
    mutations: {
        setPosts(state, value) {
            state.posts = value
        },
        setPageCount(state, value) {
            state.pageCount = value
        },
        setCurrentPage(state, value) {
            state.currentPage = value
        },
        setPerPage(state, value) {
            state.perPage = value
        },
        setCutEl(state, value) {
            state.cutEl = value
        },
        setScrollTopPos(state, value) {
            state.scrollTopPos = value
        },
        setPostsArrays(state, value) {
            state.postsArrays = value
        },
        setPostsTag(state, value) {
            state.postsTag = value
        },
        setHomePosts(state, value) {
            state.homePosts = value
        }
    },
    actions: {
        setPosts(context, value) {
            context.commit('setPosts', value)
        },
        setPageCount(context, value) {
            context.commit('setPageCount', value)
        },
        setCurrentPage(context, value) {
            context.commit('setCurrentPage', value)
        },
        setPerPage(context, value) {
            context.commit('setPerPage', value)
        },
        setCutEl(context, value) {
            context.commit('setCutEl', value)
        },
        setScrollTopPos(context, value) {
            context.commit('setScrollTopPos', value)
        },
        setPostsArrays(context, value) {
            context.commit('setPostsArrays', value)
        },
        setPostsTag(context, value) {
            context.commit('setPostsTag', value)
        },
        setHomePosts(context, value) {
            context.commit('setHomePosts', value)
        }
    }
}

export default posts;