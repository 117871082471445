const filters = {
    state: {
        showFilter: null,
        selectedFilters: []
    },
    getters: {
        showFilter(state) {
            return state.showFilter
        },
        selectedFilters(state) {
            return state.selectedFilters
        },
    },
    mutations: {
        setShowFilter(state, value) {
            state.showFilter = value
        },
        setSelectedFilters(state, value) {
            state.selectedFilters = value
        },
    },
    actions: {
        setShowFilter(context, value) {
            context.commit('setShowFilter', value)
        },
        setSelectedFilters(context, value) {
            context.commit('setSelectedFilters', value)
        },
    }
}

export default filters;