import Vue from 'vue'
import Vuex from 'vuex'
import layout from './modules/layout'
import filters from './modules/filters'
import posts from './modules/posts'
import modal from './modules/modal'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layout,
    filters,
    posts,
    modal
  }
});
