const layout = {
    state: {
        showMenuMobile: false,
        showMenuSearch: false,
        showMenuOpen: false,
        showFollowOpen: false,
        showNavBar: false,
        isLogoOnTop: false,
        isLogoOnlyOnTop: false,
        isAfterEnterAnimation: true,
        isMobile: false,
        searchText: '',
        showCookiesOpen: false,
        showNewsletterOpen: false,
        scrollPos: 0
    },
    getters: {
        showMenuMobile(state) {
            return state.showMenuMobile
        },
        showMenuSearch(state) {
            return state.showMenuSearch
        },
        showMenuOpen(state) {
            return state.showMenuOpen
        },
        showFollowOpen(state) {
            return state.showFollowOpen
        },
        showNavBar(state) {
            return state.showNavBar
        },
        isLogoOnTop(state) {
            return state.isLogoOnTop
        },
        isLogoOnlyOnTop(state) {
            return state.isLogoOnlyOnTop
        },
        isAfterEnterAnimation(state) {
            return state.isAfterEnterAnimation
        },
        isMobile(state) {
            return state.isMobile
        },
        searchText(state) {
            return state.searchText
        },
        showCookiesOpen(state) {
            return state.showCookiesOpen
        },
        showNewsletterOpen(state) {
            return state.showNewsletterOpen
        },
        scrollPos(state) {
            return state.scrollPos
        }
    },
    mutations: {
        setMenuMobile(state, value) {
            state.showMenuMobile = value
        },
        setMenuSearch(state, value) {
            state.showMenuSearch = value
        },
        setMenuOpen(state, value) {
            state.showMenuOpen = value
        },
        setFollowOpen(state, value) {
            state.showFollowOpen = value
        },
        setNavBar(state, value) {
            state.showNavBar = value
        },
        setLogoOnTop(state, value) {
            state.isLogoOnTop = value
        },
        setLogoOnlyOnTop(state, value) {
            state.isLogoOnlyOnTop = value
        },
        setAfterEnterAnimation(state, value) {
            state.isAfterEnterAnimation = value
        },
        setIsMobile(state, value) {
            state.isMobile = value
        },
        setSearchText(state, value) {
            state.searchText = value
        },
        toggleMenu(state) {
            if (!state.isMobile && window.pageYOffset < 80) {
                state.showMenuMobile = !state.showMenuMobile;
            }
            if (window.pageYOffset < 80 && !state.isLogoOnlyOnTop) {
                state.isLogoOnTop = !state.isLogoOnTop;
            }
            state.showMenuOpen = !state.showMenuOpen;
        },
        setShowCookies(state, value) {
            state.showCookiesOpen = value
        },
        setNewsletterOpen(state, value) {
            state.showNewsletterOpen = value
        },
        setScrollPos(state, value) {
            state.scrollPos = value
        }
    },
    actions: {
        setMenuMobile(context, value) {
            context.commit('setMenuMobile', value)
        },
        setMenuSearch(context, value) {
            context.commit('setMenuSearch', value)
        },
        setMenuOpen(context, value) {
            context.commit('setMenuOpen', value)
        },
        setFollowOpen(context, value) {
            context.commit('setFollowOpen', value)
        },
        setNavBar(context, value) {
            context.commit('setNavBar', value)
        },
        setLogoOnTop(context, value) {
            context.commit('setLogoOnTop', value)
        },
        setLogoOnlyOnTop(context, value) {
            context.commit('setLogoOnlyOnTop', value)
        },
        setAfterEnterAnimation(context, value) {
            context.commit('setAfterEnterAnimation', value)
        },
        setIsMobile(context, value) {
            context.commit('setIsMobile', value)
        },
        setSearchText(context, value) {
            context.commit('setSearchText', value)
        },
        toggleMenu(context) {
            context.commit('toggleMenu')
        },
        setShowCookies(context, value) {
            context.commit('setShowCookies', value)
        },
        setNewsletterOpen(context, value) {
            context.commit('setNewsletterOpen', value)
        },
        setScrollPos(context, value) {
            context.commit('setScrollPos', value)
        }
    }
}

export default layout;